import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import Super73App from '~/components/Super73App'
import React from 'react'

const Super73AppPage = () => {
  return (
    <Layout showBreadcrumbs={false}>
      <Metadata title="Super73 App" />
      <Super73App />
    </Layout>
  )
}
export default Super73AppPage
