/**@jsx jsx */
import { Box, Heading, Text, jsx } from 'theme-ui'
import Slider from 'react-slick'
import useMatchMedia from '~/hooks/utils/useMatchMedia'

const screenshots = [
  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_LogIn.png?v=1590173418',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_Pairing.png?v=1590173412',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_Pairing1.png?v=1590173412',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_Pairing2.png?v=1590173416',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_Paired.png?v=1590173416',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_Paired_Confirmation.png?v=1590173415',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_Addresses.png?v=1590173418',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_Map_AddAddress.png?v=1590173417',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_Map.png?v=1590173416',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_BikeInfo.png?v=1590173416',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_Settings.png?v=1590173414',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_ModeSelection.png?v=1590173413',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_ModeStatus.png?v=1590173413',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_BikeInfo2.png?v=1590173413',

  'https://cdn.shopify.com/s/files/1/1374/3231/files/Super73_App_ActivityFeed.png?v=1590173412'
]
const AppScreenshots = () => {
  const isDesktop = useMatchMedia('(min-width: 1024px)')
  const settings = {
    dots: true,
    slidesToShow: isDesktop ? 5 : 1,
    arrows: false
  }
  return (
    <Box
      sx={{
        backgroundColor: '#2a2f30',
        color: 'white',
        textAlign: 'center',
        padding: '120px 20px 245px',
        '& > div': {
          margin: ['30px auto 0', '70px auto 0'],
          maxWidth: '1920px'
        },
        img: { maxWidth: '400px' },
        '.slick-slide': {
          padding: '0 27px'
        },
        '.slick-dots': {
          bottom: '-100px',
          li: {
            margin: 0
          }
        },
        '.slick-dots li button::before': {
          fontSize: '13px',
          color: '#686c6e',
          opacity: 1
        },
        '.slick-dots li.slick-active button::before': {
          color: '#08a9f0'
        }
      }}
    >
      <Heading as="h2" sx={{ fontSize: 7, mb: 3 }}>
        APP SCREENSHOTS
      </Heading>
      <Text as="p" sx={{ fontSize: 3 }}>
        The simple, intuitive and powerful app to gain control of your bike.
      </Text>
      <Slider {...settings}>
        {screenshots.map((url, idx) => (
          <img src={url} alt={`screenshot of the Super73 app ${idx}`} />
        ))}
      </Slider>
    </Box>
  )
}

export default AppScreenshots
