/**@jsx jsx */
import { Button, Flex, Text } from '@theme-ui/components'
import { jsx } from 'theme-ui'
import { VideoButton } from '../Generic/VideoPlayer/styles'
import PlayVideoButtonIcon from '~/assets/images/icons/video-player.svg'
import { IoLogoAndroid, IoLogoApple } from 'react-icons/io'

const AppHero = () => {
  return (
    <Flex
      sx={{
        flexDirection: ['column', null, 'row'],
        backgroundImage:
          'url(https://images.ctfassets.net/tu8gkjqcgjw9/lIm4ZTlVkaUyIz9wnvWJs/c837b4bf122dd31912445535b7d7b6d3/hero-bg.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        justifyContent: 'center',
        paddingTop: [4, null, 7],
        px: 4,
        gap: [6, null, 5, 8],
        textAlign: ['center', null, 'left']
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          maxWidth: '600px',
          mt: 6
        }}
      >
        <h1 sx={{ textTransform: 'uppercase', mb: 0 }}>The Super73 App</h1>
        <h1 sx={{ textTransform: 'uppercase', mt: 0 }}>Has Arrived!</h1>
        <p sx={{ fontWeight: 300, fontSize: 3, m: 0 }}>
          The SUPER73 App pairs with the new generation of electric motorbikes
          and allows riders to take control of their journey. The APP offers
          turn-by-turn navigation, over the air updates and the new Multi-Ride
          mode. Download it today!
        </p>
        <Flex
          sx={{
            gap: 3,
            mt: 4,
            justifyContent: ['center', null, 'flex-start'],
            flexWrap: 'wrap',
            a: {
              ':not(:last-of-type)': {
                variant: 'buttons.inverted',
                borderRadius: '25px',
                height: '44px',
                lineHeight: '44px',
                fontSize: 3,
                svg: {
                  mr: '3px',
                  position: 'relative',
                  top: '4px',
                  width: '20px',
                  height: 'auto'
                }
              }
            }
          }}
        >
          <Button
            as="a"
            target="__blank"
            href="https://play.google.com/store/apps/details?id=com.comodule.super&hl=en_US"
          >
            <IoLogoAndroid /> ANDROID
          </Button>
          <Button
            as="a"
            target="__blank"
            href="https://apps.apple.com/us/app/super73/id1513155421"
          >
            <IoLogoApple /> IOS
          </Button>
          <VideoButton
            as="a"
            href="https://www.youtube.com/watch?v=dKCKhc3HRHw"
            target="__blank"
            sx={{
              m: 0,
              ml: 1,
              position: 'static !important',
              zIndex: 'auto !important',
              height: 'auto !important',
              width: 'auto !important',
              transformOrigin: 'top left',
              cursor: 'pointer',
              ':hover': {
                circle: {
                  opacity: 0.7
                },
                path: {
                  fill: 'white'
                }
              },
              svg: {
                width: '40px',
                height: 'auto',
                margin: 0,
                circle: {
                  opacity: 1,
                  transition: '0.25s ease'
                },
                path: {
                  fill: 'rgba(0,0,0,0.2)',
                  transition: '0.25s ease'
                }
              }
            }}
          >
            <PlayVideoButtonIcon />
            <Text sx={{ fontSize: 3, ml: 2 }}>Play</Text>
          </VideoButton>
        </Flex>
      </Flex>
      <img
        src="https://images.ctfassets.net/tu8gkjqcgjw9/2ZdcvrZKb4iICyMBa8qm1S/5b48620c8b48dd2edb77b1d62b659c01/mobile.png"
        alt="super73 app on phone preview"
        style={{ objectFit: 'contain', objectPosition: 'bottom' }}
      />
    </Flex>
  )
}

export default AppHero
