/**@jsx jsx */
import { useEffect } from 'react'
import { useState } from 'react'
import { Flex, Box, Text, Heading, jsx } from 'theme-ui'
const FEATURES = [
  {
    title: 'PAIRING YOUR BIKE',
    body: 'Pair your SUPER73 App to your bike by registering and unleashing the beast in your new ride. Once connected to your new bike, your adventure awaits! The App works with the R, RX and S2 bike models.',
    icon: 'pairing.png',
    image:
      'https://images.ctfassets.net/tu8gkjqcgjw9/3NlHfKpXr6gftUaYpydet9/9b92801e4bf6401f06181926f77ad891/pairing-large.jpg'
  },
  {
    title: '4 DIFFERENT RIDE MODES',
    body: '4 different ride modes include Class 1, 2, 3, and Unlimited Mode. Your new bike can be tailored to your riding needs while offering over-the-air vehicle firmware updates.',
    icons: 'modes.png',
    image:
      'https://images.ctfassets.net/tu8gkjqcgjw9/16zUS7fsRGQm7PR6RZwx5d/af9001f89707f770a37e23f5ce525213/modes-large.jpg'
  },
  {
    title: 'MAP',
    body: 'Map out your next ride with the available turn by turn navigation on your bike display. Save trip route, statistics, choose different routes, start/stop tracking with one tap and more.',
    icon: 'map.png',
    image:
      'https://images.ctfassets.net/tu8gkjqcgjw9/71H9TIMdKfRU4ZWEXK7nj8/81f245198b2433fd067426db09d9da78/map-large.jpg'
  },
  {
    title: 'DASHBOARD',
    body: 'The Dashboard features put you in control like never before.',
    icon: 'dashboard.png',
    image:
      'https://images.ctfassets.net/tu8gkjqcgjw9/264Rm6Kw9yRsNcOalxpauy/23c067c02dbcdc1a91fb60db7abd36df/dashboard-large.jpg'
  }
]
const AppFeatures = () => {
  const [currentFeature, setCurrentFeature] = useState(0)
  const [currentImg, setCurrentImg] = useState(FEATURES[0].image)
  const [imgOpacity, setImgOpacity] = useState(1)
  const [imgLoaded, setImgLoaded] = useState(true)

  useEffect(() => {
    setImgOpacity(0)
    setImgLoaded(false)
    setTimeout(() => {
      setCurrentImg(FEATURES[currentFeature].image)
    }, 250)
  }, [currentFeature])

  useEffect(() => {
    if (imgLoaded) {
      setTimeout(() => {
        setImgOpacity(1)
      }, 250)
    }
  }, [imgLoaded])

  return (
    <Flex
      sx={{
        backgroundImage:
          'url(https://images.ctfassets.net/tu8gkjqcgjw9/UxQ6xdMGuFH31QJnaTXIA/c2f12ff0d5c8e2c2e27bb8f3684987c3/feature-bg.jpg)',
        p: ['115px 0 70px'],
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        flexDirection: 'column',
        gap: 4
      }}
    >
      <Heading as="h2" sx={{ fontSize: 7 }}>
        FEATURES
      </Heading>
      <Flex
        sx={{
          gap: [4, null, 6, 8],
          flexDirection: ['column', null, 'row'],
          padding: '20px'
        }}
      >
        <img
          src={currentImg}
          alt="App view"
          loading="eager"
          onLoad={() => setImgLoaded(true)}
          sx={{
            maxWidth: '360px',
            objectFit: 'contain',
            opacity: imgOpacity,
            transition: 'opacity 0.25s ease',
            width: '100%',
            m: '0 auto'
          }}
        />
        <Flex sx={{ flexDirection: 'column', gap: 4 }}>
          {FEATURES.map((feature, i) => {
            return (
              <button
                sx={{
                  background: currentFeature === i ? 'black' : 'transparent',
                  color: 'inherit',
                  padding: 4,
                  border: 'none',
                  cursor: 'pointer',
                  transition: '0.3s ease',
                  borderRadius: '10px',
                  textAlign: 'left',
                  maxWidth: '500px',
                  ':hover': {
                    background: 'black'
                  }
                }}
                onClick={() => setCurrentFeature(i)}
              >
                <Heading as="h3" sx={{ fontSize: 5, mb: 3 }}>
                  {feature.title}
                </Heading>
                <Text sx={{ fontSize: 3 }}>{feature.body}</Text>
              </button>
            )
          })}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AppFeatures
