import { Container } from '@theme-ui/components'
import React from 'react'
import AppFeatures from './Features'
import AppFooter from './Footer'
import AppHero from './Hero'
import AppScreenshots from './Screenshots'

const Super73App = () => {
  return (
    <Container variant="fullWidth">
      <AppHero />
      <AppFeatures />
      <AppScreenshots />
      <AppFooter />
    </Container>
  )
}

export default Super73App
