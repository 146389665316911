/**@jsx jsx */
import { IoLogoAndroid, IoLogoApple } from 'react-icons/io'
import { Flex, Heading, Button, Text, jsx } from 'theme-ui'
const AppFooter = () => {
  return (
    <Flex
      sx={{
        color: 'white',
        backgroundColor: '#222629',
        backgroundImage:
          'url(https://images.ctfassets.net/tu8gkjqcgjw9/6DnjxPApp905S8ks5P2w6b/bb7c6361acdcfa3229bbc9a93e390b18/curve-shape.png)',
        backgroundPosition: 'bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        padding: '115px 20px 120px',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        a: {
          borderRadius: '25px',
          height: '44px',
          lineHeight: '44px',
          fontSize: 3,
          border: 'none',
          backgroundColor: 'white',
          svg: {
            mr: '3px',
            position: 'relative',
            top: '3.5px',
            width: '20px',
            height: 'auto'
          },
          ':hover': {
            backgroundColor: ' #231240'
          }
        }
      }}
    >
      <Heading as="h2" sx={{ fontSize: 7, mb: 3 }}>
        DOWNLOAD SUPER73 APP
      </Heading>
      <Text as="p" sx={{ fontSize: 3 }}>
        Get the latest version of the SUPER73 App. The App works with the R, RX
        and S2 bike models.
      </Text>
      <Flex
        sx={{
          justifyContent: 'center',
          gap: '20px',
          mt: '50px',
          flexDirection: ['column', 'row']
        }}
      >
        <Button
          as="a"
          target="__blank"
          href="https://play.google.com/store/apps/details?id=com.comodule.super&hl=en_US"
        >
          <IoLogoAndroid /> GOOGLE PLAY
        </Button>
        <Button
          as="a"
          target="__blank"
          href="https://apps.apple.com/us/app/super73/id1513155421"
        >
          <IoLogoApple /> APP STORE
        </Button>
      </Flex>
    </Flex>
  )
}

export default AppFooter
